<template>
  <div class="hospital-list">
    <CRow>
      <CCol class="col-lg-8 col-md-8 col-sm-12">
        <h1>Registered Hospital Recruiters</h1>
      </CCol>
      <CCol class="col-lg-4 col-md-4 col-sm-12">
        <CButton
          type="submit"
          @click="addRecruiter"
          color="primary"
          style="float: right"
          :disabled="count"
        >Add Recruiters</CButton>
      </CCol>
    </CRow>
    <CDataTable
      :striped="striped"
      :bordered="bordered"
      :small="small"
      :items="getItems"
      :fields="fields"
      :items-per-page="small ? limit : 5"
      pagination
    ></CDataTable>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HospitalAdminList",
  props: {
    activePage: {
      type: Number,
      default: 1
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 10
    },
    fields: {
      type: Array,
      default() {
        return [
          "Name",
          "Contact_No",
          "Email",
          "Hospital_Name"
          // "Date_of_Birth",
          // "Website"
        ];
      }
    }
  },
  data() {
    return {
      small: true,
      striped: true,
      bordered: true
    };
  },
  computed: {
    ...mapGetters(["getHospitalRecruitersList"]),
    getItems() {
      return this.getHospitalRecruitersList.map(data => {
        const container = {};
        container.Name = data.first_name.concat(" ", data.surname);
        container.Contact_No = data.contact_phone ? data.contact_phone : "--";
        container.Email = data.email ? data.email : "--";
        container.Hospital_Name =
          data.customer && data.customer.name ? data.customer.name : "--";
        // container.Date_of_Birth = data.dob ? data.dob : '--'
        // container.Website= '--'
        return container;
      });
    },
    count() {
      if (
        this.getHospitalRecruitersList &&
        this.getHospitalRecruitersList.length === 5
      ) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.FetchHospitalRecruitersList();
  },
  methods: {
    ...mapActions(["FetchHospitalRecruitersList"]),
    addRecruiter() {
      this.$router.push({ path: "/add-hospital-recruiters" });
    }
  }
};
</script>

<style scoped>
.hospital-list {
  margin-bottom: 5rem;
}
</style>